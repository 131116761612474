import Logo from './Logo';
//import Mesh from '../Fiber/Mesh';

export default function Footer() {
	return(
	    <footer>
	      <div style={{ flexGrow:1 }} />
	      <div className="centered" >

		      <div style={{ padding:'20px' }} >

		        <div style={{ margin:'0 0 20px -3px', opacity:'0.5' }} >
			      	<Logo backgroundColor="#212121" color="#fff" helperColor="#fff" textColor="#bbb" />
		        </div>

		        <span style={{ opacity:'0.5' }}>Laxious™ is a premium sustainable salmon brand of Njord Nordic Ltd.</span>
	    		{/*
		        <span style={{ color:'#f6f1eb' }} >
		          Njord Nordic Ltd
		        </span>
		        */}

		        <br/><br/>
		        <span style={{ opacity:'0.5' }} >
		          © Njord Nordic Ltd 2021.
		        </span>

		        <br/><br/>
		        <span style={{ opacity:'0.5' }} >
		          All rights reserved.
		        </span>

		        <div style={{ height:'40px' }} />

		      </div>


	      </div>
	      <div style={{ flexGrow:1 }} />
	    </footer>
	)
}
